import { Controller } from "stimulus"
import $ from 'jquery';

require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")

import Select2 from "select2"

export default class extends Controller {
  static targets = ["output"]

  connect() {
    console.log('promotion-edit-controller');

    this.start_date = flatpickr("#promo-start", {
      enableTime: true,
      dateFormat: "Y-m-d H:i"
    });
    this.stop_date = flatpickr("#promo-stop", {
      enableTime: true,
      dateFormat: "Y-m-d H:i"
    });
    this.submission_stop_date = flatpickr("#submission-stop", {
      enableTime: true,
      dateFormat: "Y-m-d H:i"
    });

    $('.promotion-genres').select2({
      tokenSeparators: [',', ' ']
    });
  }

  clear_start(event) {
    console.log('clear_start');
    event.preventDefault();
    this.start_date.clear();
  }
  
  clear_stop(event) {
    console.log('clear_stop');
    event.preventDefault();
    this.stop_date.clear();
  }
  
  clear_submission_stop(event) {
    console.log('clear_submission_stop');
    event.preventDefault();
    this.submission_stop_date.clear();
  }
}
