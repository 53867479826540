import { Controller } from "stimulus"
import $ from 'jquery';

export default class extends Controller {
  static targets = ["numSelected", 'checkbox']

  connect() {
    console.log('connecting PickRecipients controller');

    $("#subscriber-tags-select").on('select2:select select2:unselect', function () {
      console.log("list item selected");
      let event = new Event('change', { bubbles: true })
      this.dispatchEvent(event);
    });

    this.signup_date = flatpickr(".datepicker", {
      enableTime: true,
      dateFormat: "Y-m-d H:i"
    });

    this.updateToTagOptions();
  }

  clear_date(event) {
    console.log('clear_signup_date');
    const element = event.target

    event.preventDefault();

    var target_field = element.dataset.fieldTarget;

    console.log("target_field " + target_field);

    if (target_field == 'signup-date') {
      this.signup_date.clear();
    }
  }

  updateToTagOptions() {
    console.log("updateToTagOptions");
    // this.clearResult()

    var fields = $("#pick-recipients-form").find("[name!='_method']");
    var ourData = fields.serialize() + "&filter=1";

    $.ajax({
      type: "GET",
      url: "/recipients/filter_count.json",
      data: ourData,
      success: (data) => {
        console.log('Test json were got!')
        this.numSelectedTarget.innerHTML = data.count;
      }
    })
  }
}
