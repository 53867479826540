// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
import 'bootstrap/dist/js/bootstrap';
import 'popper.js/dist/popper.js';

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// import 'bootstrap/dist/js/bootstrap.bundle';
// require('./bootstrap_custom.js')
require('./jquery.easing.min.js')

import '../stylesheets/application'

import toastr from 'toastr';

toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
  };

global.toastr = toastr;
window.toastr = toastr;

import '@client-side-validations/client-side-validations'

import LocalTime from "local-time"
LocalTime.start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")

// datatables
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-responsive-bs4');
require('datatables.net-rowgroup-bs4');
require('jszip');
require('pdfmake');
import 'datatables.net-bs4/css/dataTables.bootstrap4.css';

import 'bootstrap-colorpicker/dist/js/bootstrap-colorpicker';

import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';

import 'bootstrap4-toggle/js/bootstrap4-toggle.min'
import 'bootstrap4-toggle/css/bootstrap4-toggle.min'

function onready() {
  console.log('onready')
}

$(document).on('ready turbolinks:load', onready);

document.addEventListener("turbolinks:load", () => {
  var ourTables = $('.philTable');
  ourTables.each(function (index, thisTable) {
    console.log('before ' + $(thisTable).data('mrPip'));

    if ($(thisTable).data('mrPip') != 'initialized') {
      $(thisTable).DataTable({
        pageLength: 25,
        lengthChange: false
      });
      $(thisTable).data('mrPip', 'initialized');
      console.log('after ' + $(thisTable).data('mrPip'));
    }

  });
});

document.addEventListener("turbolinks:before-cache", () => {
  console.log('turbolinks:before-cache')
  console.log('A dataTable.length = ' + $('.philTable').length);

  var ourTables = $('.philTable');
  ourTables.each(function (index, thisTable) {
    $(thisTable).destroy();
  });
  Turbolinks.clearCache();
  console.log('B dataTable.length = ' + $('.philTable').length);

  if (document.body.classList.contains('modal-open')) {
    $('.modal')
      .hide()
      .removeAttr('aria-modal')
      .attr('aria-hidden', 'true');
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
  }
});

import ClipboardJS from 'clipboard';

document.addEventListener("turbolinks:load", () => {
  new ClipboardJS('.clipboard-btn');
});

require('../src/custom')
import "../src/trix-editor-overrides"

import "controllers"
