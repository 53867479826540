import { Controller } from "stimulus"
import $ from 'jquery';

require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")

import Select2 from "select2"

export default class extends Controller {
  connect() {
    // promotion page edit
    console.log('connecting PPE controller');
    $('#button_color_control').colorpicker();
    $('#button_bg_color_control').colorpicker();
    $('#title_color_control').colorpicker();
    $('#title_color_control').colorpicker();
    $('#page_bg_color_control').colorpicker();
  }

  load_preview(e) {
    console.log('load_preview');
    e.preventDefault();
    var fields = $("#promotion_page_edit_form").find("[name!='_method']");
    var ourData = fields.serialize() + "&preview_request=1";

    $.ajax({
      url: '/promotion_pages',
      dataType: 'json',
      data: ourData,
      method: 'POST',
      success: function (d) {//d is the return/response of your url (your question answer)
        $('#ppe-preview-dialog').modal();
        $('#ppe-preview-frame').attr('src', d.location);
      },
    });
  }

  load_thank_you_preview(e) {
    console.log('load_thank_you_preview');
    e.preventDefault();
    var fields = $("#promotion").find("[name!='_method']");
    var ourData = fields.serialize() + "&preview_request=1&thank_you_preview=1";

    $.ajax({
      url: '/promotion_pages',
      dataType: 'json',
      data: ourData,
      method: 'POST',
      success: function (d) {//d is the return/response of your url (your question answer)
        $('#ppe-preview-dialog').modal();
        $('#ppe-preview-frame').attr('src', d.location);
      },
    });
  }

  alter_mode(e) {
    var O = $('#preview-frame');
    e.preventDefault();
    var newTargetSize = this.data.get('target');
    "mobile" == newTargetSize ? O.css("width", 375).css("height", 550) : "tablet" == newTargetSize ? O.css("width", 790).css("height", 700) : "desktop" == newTargetSize && O.css("width", 1024).css("height", 700);
  }

  clear_start(event) {
    console.log('clear_start');
    event.preventDefault();
    this.start_date.clear();
  }

  clear_stop(event) {
    console.log('clear_stop');
    event.preventDefault();
    this.stop_date.clear();
  }

}