import { Controller } from "stimulus"
import $ from 'jquery';

export default class extends Controller {
  static targets = ["output"]

  connect() {
    console.log('connecting PickRecipients controller');

    this.run_at = flatpickr(".datepicker", {
      enableTime: true,
      dateFormat: "Y-m-d H:i"
    });

  }

  clear_date(event) {
    console.log('clear_run_at');
    const element = event.target

    event.preventDefault();

    var target_field = element.dataset.fieldTarget;

    console.log("target_field " + target_field);

    if (target_field == 'signup-date') {
      this.run_at.clear();
    }
  }
}
