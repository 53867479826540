import { Controller } from "stimulus"

export default class extends Controller {
  static targets=["output"]

  update_edit_form() {
    console.log('update_edit_form');
    var name = this.data.get('name');
    var id = this.data.get('pen-name-id');
    var email = this.data.get('email');
    var url = this.data.get('url');
    var privacy_url = this.data.get('privacy-policy-url');
  
    $("#id").val(id);
    $("#name").val(name);
    $("#email").val(email);
    $("#url").val(url);
  
    $("#update_pen_name").attr("action", "/pen_names/" + id);
  }
}