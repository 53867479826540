import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// datatables
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-responsive-bs4');
require('datatables.net-rowgroup-bs4');
require('jszip');
require('pdfmake');

require("chartkick")
require("chart.js")


import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["output"]

  connect() {
    console.log('promotions-index-controller');

    $('#dataTables-upcoming-promotions').DataTable({
      pageLength: 25,
      ordering: true,
      order: [[2, 'asc'], [3, 'asc']],
      lengthChange: false
    });
  }
}