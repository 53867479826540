import { Controller } from "stimulus"

// import Stripe from 'stripe';
// const stripe = Stripe('sk_test_7vjzuzX1SHPkxKvvRWfEgh4500uN1Vj03o');

export default class extends Controller {
    static targets = ["form", "card", "errors"]

    connect() {
        console.log('signup connected');

        this.stripe = Stripe('pk_test_rdbPrORt3mP5mTWLa7maDLr600pogSGPKP')

        const elements = this.stripe.elements({
            fonts: [
                {
                    cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
                },
            ]
        });
        const style = {
            base: {
                color: "#32325d",
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                ':-webkit-autofill': {
                    color: '#fce883',
                },
                "::placeholder": {
                    color: "#aab7c4"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        };

        this.card = elements.create("card", { style: style })
        this.card.mount(this.cardTarget)
    }

    change(event) {
        if (event.error) {
            this.errorsTarget.textContent = event.error.message
        } else {
            this.errorsTarget.textContent = ""
        }
    }

    submit(event) {
        event.preventDefault()

        console.log('submit');
        this.changeLoadingState(true);

        var cardholderEmail = this.data.get('email');
        this.stripe
            .createPaymentMethod('card', this.card, {
                billing_details: {
                    email: cardholderEmail
                }
            })
            .then((result) => this.dealWithPaymentMethodResult(result));
    };

    dealWithPaymentMethodResult(result) {
        if (result.error) {
            this.changeLoadingState(false);
            var displayError = document.getElementById('card-errors');
            displayError.textContent = result.error;
        } else {
            this.createCustomer(result.paymentMethod.id, this.data.get('email'));
        }
    }

    createCustomer(paymentMethod, cardholderEmail) {
        return fetch('/authors/stripe/create_customer', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: cardholderEmail,
                payment_method: paymentMethod
            })
        })
            .then(response => {
                return response.json();
            })
            .then((subscription) => {
                this.handleSubscription(subscription);
            });
    }

    handleSubscription(subscription) {
        const { latest_invoice } = subscription;
        const { payment_intent } = latest_invoice;

        if (payment_intent) {
            const { client_secret, status } = payment_intent;

            if (status === 'requires_action' || status === 'requires_payment_method') {
                this.stripe.confirmCardPayment(client_secret).then(function (result) {
                    if (result.error) {
                        // Display error message in your UI.
                        // The card was declined (i.e. insufficient funds, card has expired, etc)
                        this.changeLoadingState(false);
                        this.errorsTarget.textContent = result.error
                    } else {
                        // Show a success message to your customer
                        this.confirmSubscription(subscription.id);
                    }
                });
            } else {
                // No additional information was needed
                // Show a success message to your customer
                this.orderComplete(subscription);
            }
        } else {
            this.orderComplete(subscription);
        }
    }
    
    orderComplete(subscription) {
        this.changeLoadingState(false);
        var subscriptionJson = JSON.stringify(subscription, null, 2);
        $("#payment").modal("hide")
        // document.querySelectorAll('.payment-view').forEach(function(view) {
        //   view.classList.add('hidden');
        // });
        // document.querySelectorAll('.completed-view').forEach(function(view) {
        //   view.classList.remove('hidden');
        // });
        // document.querySelector('.order-status').textContent = subscription.status;
        // document.querySelector('code').textContent = subscriptionJson;
      };
      

    changeLoadingState(isLoading) {
        if (isLoading) {
          $('#spinner').addClass('loading');
          $('button').prop('disabled', true);
      
          $('#button-text').addClass('hidden');
        } else {
          $('button').prop('disabled', false)
          $('#spinner').removeClass('loading');
          $('#button-text').removeClass('hidden');
        }
      };

      confirmSubscription(subscriptionId) {
        return fetch('/subscription', {
          method: 'post',
          headers: {
            'Content-type': 'application/json'
          },
          body: JSON.stringify({
            subscriptionId: subscriptionId
          })
        })
          .then(function(response) {
            return response.json();
          })
          .then((subscription) => {
            this.orderComplete(subscription);
          });
      }
}
