import { Controller } from "stimulus"
import $ from 'jquery';

require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")

import Select2 from "select2"


export default class extends Controller {
  connect() {
    $('.subscriber-tags').select2({
      tags: true,
      tokenSeparators: [',', ' ']
    });

    $(".select-only-tags").select2({
      createToken: function(params) {
        return null;
      }
    });
  }
}