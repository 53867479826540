import { Controller } from "stimulus"

import $ from 'jquery';

require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")

import Select2 from "select2"
import Cleave from 'cleave.js';

require("emojionearea/dist/emojionearea")
require("emojionearea/dist/emojionearea.min.css")

export default class extends Controller {
  static targets = ["output"]

  connect() {
    $(".emojionearea").emojioneArea({
      emojiPlaceholder: ":smile_cat:"
    });
    
    $("#one-subject").hide();
  }

  ab_content(e) {
    e.preventDefault();
    $(".ab-subjects").hide();
    $("#one-subject").show();
  }

  ab_subject(e) {
    e.preventDefault();
    $(".ab-subjects").show();
    $("#one-subject").hide();
  }
}
