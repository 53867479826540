import { Controller } from "stimulus"
import $ from 'jquery';
import ahoy from 'ahoy.js';

export default class extends Controller {

  connect() {
    console.log('ppv connect');
    ahoy.debug();
    ahoy.configure({
      promotion_slug: this.data.get('promotion-id'),
      author_uuid: this.data.get('author-uuid')
    });
  }

  click(e) {
    console.log('ppv click');
    
    ahoy.track('Clicked Promotion Page', {
      participant_uuid: this.data.get('participant-uuid'),
      page_label: this.data.get('page-label'),
      book_uuid: this.data.get('book-uuid'),
      author_uuid: this.data.get('author-uuid'),
      promotion_slug: this.data.get('promotion-slug')
    });
  }
}
