import { Controller } from "stimulus"
import $ from 'jquery';

import ahoy from 'ahoy.js';

export default class extends Controller {
  static targets = [ 'btn' ]

  initialize() {
    console.log('lpg initialize');
  }

  connect() {
    console.log('lpg connect');
    ahoy.configure({
      delivery_page_id: this.data.get('delivery-page-id'),
      subscriber_uuid: this.data.get('subscriber-id'),
      book_uuid: this.data.get('book-uuid'),
      author_uuid: this.data.get('author-uuid')
    });

    console.log(this.data.get('delivery-page-id'));
    console.log(this.data.get('subscriber-id'));
    console.log(this.data.get('book-uuid'));
    console.log(this.data.get('author-uuid'));
    // ahoy.trackAll();
    ahoy.trackView({
      delivery_page_id: this.data.get('delivery-page-id'),
      subscriber_uuid: this.data.get('subscriber-id'),
      book_uuid: this.data.get('book-uuid'),
      author_uuid: this.data.get('author-uuid')
    });

    ahoy.debug();
  }

  pdf_download_click(e) {
    console.log('pdf click');
    ahoy.track('Download Click', {
      delivery_page_id: this.data.get('delivery-page-id'),
      subscriber_uuid: this.data.get('subscriber-id'),
      book_uuid: this.data.get('book-uuid'),
      author_uuid: this.data.get('author-uuid'),
      download_type: 'pdf'
    });
  }

  mobi_download_click(e) {
    console.log('pdf click');
    ahoy.track('Download Click', {
      delivery_page_id: this.data.get('delivery-page-id'),
      subscriber_uuid: this.data.get('subscriber-id'),
      book_uuid: this.data.get('book-uuid'),
      author_uuid: this.data.get('author-uuid'),
      download_type: 'mobi'
    });
  }

  epub_download_click(e) {
    console.log('pdf click');
    ahoy.track('Download Click', {
      delivery_page_id: this.data.get('delivery-page-id'),
      subscriber_uuid: this.data.get('subscriber-id'),
      book_uuid: this.data.get('book-uuid'),
      author_uuid: this.data.get('author-uuid'),
      download_type: 'epub'
    });
  }
}
