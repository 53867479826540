import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// datatables
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-responsive-bs4');
require('datatables.net-rowgroup-bs4');
require('jszip');
require('pdfmake');

require("chartkick")
require("chart.js")


import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["output"]

  connect() {
    $('#dataTables-signups').DataTable({
      pageLength: 25,
      // dom: 'Bfrtip',
      dom: "<'row'<'col-sm-1 text-left'lB><'col-sm-9 text-right'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-5'i><'col-sm-7'p>>",
      buttons: [
        {
          extend: 'csv',
          text: 'Export CSV',
          filename: this.data.get('author-name') + '-signups-data',
          exportOptions: {
            columns: [0, 1, 3, 4, 5, 7, 8]
          }
        }
      ],
      "columnDefs": [
        {
          "targets": [1],
          "visible": false,
          "searchable": false
        },
        {
          "targets": [3],
          "visible": false,
          "searchable": false
        },
        {
          "targets": [7],
          "searchable": false,
          "visible": false
        },
        {
          "targets": [8],
          "searchable": false,
          "visible": false
        }
      ]
    });

  }
}
