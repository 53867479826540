require("chartkick")
require("chart.js")


// require('../src/charts')

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]

  connect() {
    console.log('dashboard controller');
  }
}
