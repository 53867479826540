
import { Controller } from "stimulus"
import $ from 'jquery';

export default class extends Controller {
//  static targets = [ "output" ]

  connect() {
    // this.outputTarget.textContent = 'Hello, Stimulus!'
  }

  load_preview(e) {
    console.log('load_preview ' + this.data.get('url'));
    e.preventDefault();
    var ourData = "preview_request=1";

    $('#pp-preview-dialog').modal();
    $('#pp-preview-frame').attr('src', this.data.get('url'));
  }

  alter_mode(e) {
    console.log('alter_mode');
    var O = $('#pp-preview-frame');
    e.preventDefault();
    var newTargetSize = this.data.get('target');
    "mobile" == newTargetSize ? O.css("width", 375).css("height", 550) : "tablet" == newTargetSize ? O.css("width", 790).css("height", 700) : "desktop" == newTargetSize && O.css("width", 1024).css("height", 700);
  }


}
